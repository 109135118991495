import React from 'react'
import { CButton, CButtonGroup } from '@coreui/react'
import { IconContext } from 'react-icons'
import { HiChevronRight, HiChevronLeft } from 'react-icons/hi'
import PropTypes from 'prop-types'

PaginationButtonEnhance.propTypes = {
  nextPageUrl: PropTypes.string,
  prevPageUrl: PropTypes.string,
  total: PropTypes.number,
  size: PropTypes.number,
  page: PropTypes.number,
  setPage: PropTypes.func,
}

export default function PaginationButtonEnhance({
  nextPageUrl,
  prevPageUrl,
  total,
  size = 10,
  page = 1,
  setPage = null,
}) {
  const btnStyle = {
    borderColor: '#CCCCCC',
    color: '#2C384AF2',
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 0,
  }

  const lastPage = Math.ceil(total / size)

  let numShown = Math.ceil(total / size) > 5 ? 5 : Math.ceil(total / size)

  numShown = Math.min(numShown, total)
  let first = page - Math.floor(numShown / 2)
  first = Math.max(first, 1)
  first = Math.min(first, total - numShown + 1)

  const arr = [...Array(numShown)].map((k, i) => {
    return i + first
  })

  return (
    <CButtonGroup style={{ backgroundColor: 'white' }}>
      <CButton
        color="primary"
        variant="outline"
        style={btnStyle}
        onClick={() => {
          setPage(Number(page) - 1)
        }}
        disabled={prevPageUrl ? false : true}
      >
        <IconContext.Provider value={{ size: '1.1em' }}>
          <div>
            <HiChevronLeft />
          </div>
        </IconContext.Provider>
      </CButton>

      {arr.map((el, i) => {
        // if (el > 7) {
        //   console.log('disabled')
        // }
        return (
          <div key={i}>
            <CButton
              color="primary"
              variant="outline"
              style={btnStyle}
              value={el}
              active={page === el && true}
              onClick={(e) => {
                setPage(Number(e.target.value))
              }}
              disabled={(el > page && !nextPageUrl) || el > lastPage}
            >
              {el}
            </CButton>
          </div>
        )
      })}

      <CButton
        color="primary"
        variant="outline"
        style={btnStyle}
        onClick={() => {
          setPage(Number(page) + 1)
        }}
        disabled={nextPageUrl ? false : true}
      >
        <IconContext.Provider value={{ size: '1.1em' }}>
          <div>
            <HiChevronRight />
          </div>
        </IconContext.Provider>
      </CButton>
    </CButtonGroup>
  )
}
