import { CFormInput } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useDebounce } from '../../hooks'
import PropTypes from 'prop-types'

ShowEntriesEnhance.propTypes = {
  size: PropTypes.number,
  setSize: PropTypes.func,
  setPage: PropTypes.func,
}

export default function ShowEntriesEnhance({ size = 10, setSize = null, setPage = null }) {
  const [input, setInput] = useState(size)

  const inputValue = useDebounce(input)

  useEffect(() => {
    setSize(Number(inputValue))
    setPage(1)
  }, [inputValue, setPage, setSize])

  return (
    <div className="d-flex " style={{ width: '160px', height: '40px', alignItems: 'center' }}>
      <small className="me-2">Show</small>
      <CFormInput
        type="number"
        min="1"
        className="me-2"
        value={input}
        onChange={(e) => {
          setInput(e.target.value)
        }}
      />

      <small>Entries</small>
    </div>
  )
}
