import { CFormInput } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

LiveSearchEnhance.propTypes = {
  placeholder: PropTypes.string,
  setKeyword: PropTypes.func,
  setPage: PropTypes.func,
}

export default function LiveSearchEnhance({ placeholder, setKeyword, setPage }) {
  const [search, setSearch] = useState('')
  useEffect(() => {
    setKeyword(search)
    setPage(1)
  }, [search, setKeyword, setPage])

  return (
    <>
      <CFormInput
        placeholder={placeholder}
        value={search}
        onChange={(e) => {
          setSearch(e.target.value)
        }}
      />
    </>
  )
}
