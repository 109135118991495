export const BASE_URL_PRODUCTION = 'https://api.seraphim-center.com/'
export const BASE_URL_STAGING = 'https://dev.bethsaidahospitals.com/apiseraphim/proxy/'
export const BASE_URL_LOCAL = 'http://localhost:3550/'
// export const BASE_URL_LOCAL = 'https://apiseraphim.test/proxy/'
//export const BASE_URL_STAGING = 'https://api.seraphim.on-dev.info/'

export const STALE_SHORT = 5000 // 5 Secs
export const STALE_MEDIUM = 1000 * 60 // 1 Minute
export const STALE_LONG = 1000 * 3600 // 1 Hour

export const STORAGE_TOKEN_KEY = 'ST'
export const STORAGE_RESET_PASSWORD_TOKEN = 'SRPT'
export const STORAGE_RESET_PASSWORD_EMAIL = 'SRPE'
export const STORAGE_RESET_PASSWORD_RESPONSE = 'SRPR'
export const USER_ROLE = 'UR'
export const LEADS_ID = 'LI'
export const SS_ENABLE = 1
export const IS_DOCTOR = 'ISDC'
export const USER_PROFILE = 'UP'
